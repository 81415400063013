.checkbox-wrapper {
  background: $secondary-color;
  overflow: hidden;

  &.rounded-border-top {
    border-radius: 1rem 1rem 0 0;
  }
  &.rounded-border-bottom {
    border-radius: 0 0 1rem 1rem;
  }

  &:not(:first-child) {
    border-top: 2px solid #fff;
  }

  &.rounded-border-bottom-comments {
    border-radius: 0 0 1rem 1rem;
    padding: 0 10px;
    border-left: 116px solid #fff;
  }

  &-label {
    color: $primary-color;
    display: flex;
    padding: 0.6rem 1rem;
    align-items: center;
    cursor: pointer;

    &.large {
      padding: 1rem 1.5rem;
      .label {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .label {
      flex: 1;
      user-select: none;
    }

    .icon {
      transition: transform 0.3s ease;
      display: inline-flex;
      align-items: center;

      &.rotate-down {
        transform: rotateZ(90deg);
      }
    }
  }

  &-options {
    overflow: hidden;
    box-sizing: border-box;
    div.chilldren-conrainer {
      box-sizing: border-box;
    }
    &.large {
      padding: 0rem 1.5rem;
    }
  }
}
