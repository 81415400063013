:root {
  --main-color: #2e538e;
  width: 100%;
}

.tooltip {
  position: relative;
  display: block;
  cursor: pointer;
}

.tooltiptext {
  width: 400px;
  background-color: var(--main-color);
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 10;
  line-height: 1;
  position: absolute;
  border-radius: 10;
  z-index: 100000;
}

.right {
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
}

.left {
  top: 50%;
  right: 110%;
  transform: translateY(-50%);
}

.top {
  width: 40%;
  padding: 2%;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
}

.bottom {
  width: 40%;
  padding: 2%;
  top: 120%;
  left: 50%;
  margin-left: -60px;
}

.left.tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--main-color);
}

.right.tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--main-color) transparent transparent;
}

.top.tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--main-color) transparent transparent transparent;
}

.bottom.tooltiptext::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--main-color) transparent;
}
