.details {
  .companySection {
    background: #f8f9fd;
    padding: 1.4rem 2.2rem;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        color: $primary-color;
        font-weight: bold;
        font-size: 1.8rem;
      }

      svg {
        height: auto;
        max-width: 60px;
      }
    }

    .data-section {
      padding: 0.9rem 1.4rem;
      border-radius: 0.5rem;

      // box-shadow: 0rem 0.3rem 1.4rem rgba($color: #000000, $alpha: 0.25);
      background: #fff;
      margin-top: 1.4rem;
      color: #9ba7b1;

      &--transparent {
        background: transparent;
      }

      &.keypeople {
        p {
          margin-top: 1rem;
        }
      }

      .label {
        color: $primary-color;
        font-weight: bold;
      }

      &.list {
        padding: 0;

        .list-item {
          color: #9ba7b1;
          box-sizing: border-box;
          padding: 1rem 1.6rem;
          display: flex;
          align-items: center;

          .label {
            width: 35%;
            display: inline-block;
            min-width: 35%;
            // height: ;
          }

          &:not(:first-child) {
            border-top: 1px solid #efefef;
          }
        }
      }

      &.website {
        .label {
          color: #9ba7b1;
        }

        a {
          text-decoration: none;
          color: $primary-color;
        }
      }

      &.fav {
        padding: 0;
        align-items: center;
      }
    }
  }

  .productSection {
    padding: 2rem 4rem;

    @media (max-width: 900px) {
      padding: 1rem 2rem;
    }

    .data-section {
      padding: 0.9rem 1.4rem;
      border-radius: 0.5rem;
      background: #f8f9fd;

      &--transparent {
        background: transparent;
      }
    }
  }
}
