*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 2.2;
  font-family: 'Open Sans';
  background: #f8f9fd;
  position: relative;
  z-index: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Scrollbar customization */
/* width */
::-webkit-scrollbar {
  width: 3px;
  padding: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.219);
  border-radius: 6px;
  padding: 1px;
  width: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Scrollbar styles for firefox
* {
  scrollbar-color: rgba(136, 136, 136, 0.519) transparent;
  scrollbar-width: thin;
}
