.radio-button {
    &-container {
        display: flex;
        flex-direction: column;

        .radio-button {
            border-radius: 0.625rem;
            color: $primary-color;
            background: $secondary-color;
            padding: 0.5rem 1rem;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            p.description {
                margin-left: 1rem;
                font-size: 0.7rem;
            }

            &:not(:first-child) {
                margin-top: 1rem;
            }

            &:hover {
                background: darken($color: $secondary-color, $amount: 3);
            }

            &.checked {
                background: $primary-color;
                color: $secondary-color;
            }

            &.disabled {
                background-color: #dddddd;
                color: black;
                cursor: not-allowed;
                opacity: 0.4;
            }
        }
    }
}