.nav {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 5rem;
  background-color: $primary-color;
  color: $text-color-light;

  ul {
    list-style-type: none;

    li {
      display: inline-block;
      font-size: 1.2rem;
      text-transform: uppercase;
      cursor: pointer;

      &.selected {
        color: #b7cdcd;
        border-bottom: 0.2rem solid #b7cdcd;
      }

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
}