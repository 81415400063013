.home-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2.5rem;
  justify-self: flex-start;

  @media (max-width: 900px) {
    padding: 1.2rem 1.2rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-logo {
    max-height: 80px;
    display: inline-block;

    @media (max-width: 900px) {
      max-height: 50px;
    }
  }

  &-linkList {
    list-style-type: none;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      margin-top: 2rem;
      width: 100%;
      justify-content: center;
    }

    &-item {
      font-weight: bold;
      color: #191a1e;

      a {
        text-decoration: none;
        color: inherit;
        display: inline-block;
      }

      &:not(:first-child) {
        margin-left: 1.8rem;
      }

      &--button {
        color: $primary-color;
        font-weight: bold;
        padding: 0.6rem 1.4rem;
        border-radius: 0.25rem;
        box-shadow: 0rem 0.45rem 1.3rem rgba($color: #000000, $alpha: 0.2);
        transition: background 0.3s ease, color 0.3s ease;
        cursor: pointer;

        &:hover {
          background: $primary-color;
          color: $text-color-light;
        }
      }
    }
  }
}

.homepage-title {
  margin-top: 2.8rem;
  text-align: center;
  font-size: 2.8rem;
}

.text-highlight {
  color: $primary-color;
}

.home-searchSection {
  width: 80%;
  margin: 4.2rem auto 5rem auto;
  display: flex;
  justify-items: center;

  .categorySelector,
  .searchBar {
    flex: 1;
    text-align: center;

    h3 {
      font-weight: normal;
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 10%;
    color: #888;

    &:before,
    &:after {
      position: absolute;
      content: '';
      height: 44%;
      left: 50%;
      width: 2px;
      background: #888;
      transform: translateX(-50%);
    }

    &:after {
      top: 0;
    }

    &:before {
      bottom: 0;
    }
  }

  .categorySelector {
    .category-tiles {
      margin-top: 1.25rem;
      display: flex;
      justify-content: space-around;

      .category-tile {
        button.btn {
          margin-top: 1.23rem;
        }

        &.selected {
          border: 1px solid opacify($color: $primary-color, $amount: 0.5);
        }

        h4 {
          font-size: 1rem;
          color: $primary-color;
          margin-bottom: 0.9rem;
        }

        p {
          font-size: 0.85rem;
        }

        cursor: pointer;
        width: 45%;
        padding: 0.85rem;
        border-radius: 0.4rem;
        box-shadow: 0rem 0.35rem 1.3rem rgba($color: #000000, $alpha: 0.2);
      }
    }
  }

  .searchBar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      margin-top: 1.4rem;
    }

    input {
      display: inline-block;
      margin-top: 1.9rem;
      width: 100%;
      box-shadow: 0rem 0.3rem 1.3rem rgba($color: #000000, $alpha: 0.2);
      border-radius: 0.6rem;
      padding: 1.3rem 2rem;
      background: #fff;
      outline: none;
      border: none;
      font-family: inherit;

      &::placeholder {
        font-family: inherit;
        color: $primary-color;
        font-weight: bold;
      }
    }
  }
}

.text-bold {
  font-weight: bold;
}

.home-header {
  flex-grow: 1;
  min-height: 0;
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  align-items: center;
  padding: 0rem 0rem 4rem 0rem;

  .home-info {
    width: 30%;
    text-align: justify;

    h2.heading {
      font-size: 1.6rem;
      text-align: left;
      @media (max-width: 600px) {
        text-align: center;
      }
    }

    ul {
      list-style-type: none;
      margin-top: 2rem;

      li {
        display: flex;

        // justify-content: center;
        align-items: center;

        &:not(:first-child) {
          margin-top: 1rem;
        }

        .icon-container {
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 600px) {
            margin-right: 10px;
          }
        }

        span {
          flex: 1;
          text-align: justify;
        }
      }
    }
  }

  .signup-form {
    margin-top: 2rem;
    width: 28.125rem;

    .signup-modal {
      // padding: 2.4rem 2rem;
      width: 100%;
      max-width: none;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2rem;

    .home-info {
      width: 100%;

      h2 {
        font-size: 1.5rem;
      }
    }

    .signup-form {
      width: 100%;
      margin-top: 4rem;

      .signup-modal {
        padding: 2.4rem 2rem;
        width: 100%;
        max-width: none;
      }
    }
  }
}
