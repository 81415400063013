.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000001;
  text-align: center;

  @media (max-width: 900px) {
    min-width: 80% !important;
    padding: 1rem 1.2rem !important;
    padding-bottom: 3.5rem !important;
  }

  &-wrapper {
    z-index: 10000000;
    position: fixed;
  }

  &-close-button {
    width: 19px;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    padding: 0.6rem;

    @media (max-width: 900px) {
      padding: 1rem;
      background: #aaa3;

      &:hover {
        background: #aaa6;
      }

      top: unset;
      right: unset;
      bottom: 0.8rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      padding-bottom: 100%;
      width: 100%;
      display: block;
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: 4px;
      transition: background 0.3s ease;
      left: 0;
      top: 0;

      &:after,
      &:before {
        position: absolute;
        content: '';
        width: 15%;
        height: 80%;
        border-radius: 3px;

        // background: #4444;
        box-shadow: inset 0rem 0.2rem 1rem rgba($color: #000000, $alpha: 0.3);
        left: 50%;
        top: 50%;
      }

      &:after {
        transform: translate(-50%, -50%) rotateZ(45deg);
      }

      &:before {
        transform: translate(-50%, -50%) rotateZ(-45deg);
      }
    }
  }

  &-overlay {
    position: fixed;
    z-index: 1000000;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #e1e3e4;
  }
}

.thankyou-modal {
  padding: 2.4rem 3.5rem;
  box-shadow: 0rem 0.3rem 1.2rem rgba($color: #000000, $alpha: 0.3);

  max-width: 600px;
  background: #ffffff;
  h6 {
    font-weight: 400;
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.signup-modal {
  padding: 2.4rem 3.5rem;
  box-shadow: 0rem 0.3rem 1.2rem rgba($color: #000000, $alpha: 0.3);
  border-radius: 0.6rem;
  max-width: 35vw;
  background: #ffffff;

  &-close-button {
    width: 19px;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;

    &::after {
      content: '';
      padding-bottom: 100%;
      width: 100%;
      display: block;
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 4px;
      transition: background 0.3s ease;

      &:hover {
        background: #ddd6;
      }

      &:after,
      &:before {
        position: absolute;
        content: '';
        width: 15%;
        height: 80%;
        border-radius: 3px;

        // background: #4444;
        box-shadow: inset 0rem 0.2rem 1rem rgba($color: #000000, $alpha: 0.3);
        left: 50%;
        top: 50%;
      }

      &:after {
        transform: translate(-50%, -50%) rotateZ(45deg);
      }

      &:before {
        transform: translate(-50%, -50%) rotateZ(-45deg);
      }
    }
  }

  &-heading {
    font-size: 1.2rem;
  }

  .info {
    font-size: 10px;
    line-height: 16px;
    color: #9ba7b1;

    a {
      text-decoration: none;
      color: $primary-color;
      font-weight: bold;
    }

    &:not(:first-child) {
      margin-top: 1.2rem;
    }
  }

  form {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;

    input {
      border-radius: 0.6rem;
      padding: 0.7rem 1.7rem;
      display: inline-block;
      background: #f8f9fd;
      width: 100%;
      outline: none;
      border: none;
      font-family: inherit;
      transition: background 0.3s ease;

      & + span.hint {
        margin-top: 14px;
        width: 100%;
        display: inline-flex;
        font-size: 12px;
        border-radius: 0.6rem;
        font-family: inherit;
        padding: 0.4rem 1.7rem;
        padding-left: 0.4rem;
        align-items: center;
        opacity: 0.7;

        svg {
          font-size: 0.9rem;
          display: inline-block;
          margin-right: 0.3rem;
        }

        &.error {
          color: #b82828;
          background: #e29292e3;
        }
      }

      &:focus {
        background: darken($color: rgb(238, 240, 248), $amount: 4);
      }

      &:not(:first-child) {
        margin-top: 0.6rem;
      }
    }

    .info {
      font-size: 10px;
      line-height: 16px;
      color: #9ba7b1;

      a {
        text-decoration: none;
        color: $primary-color;
        font-weight: bold;
      }

      &:not(:first-child) {
        margin-top: 1.2rem;
      }
    }

    form {
      margin-top: 1.3rem;
      margin-bottom: 1.3rem;

      input {
        border-radius: 0.6rem;
        padding: 0.7rem 1.7rem;
        display: inline-block;
        background: #f8f9fd;
        width: 100%;
        outline: none;
        border: none;
        font-family: inherit;
        transition: background 0.3s ease;

        & + span.hint {
          margin-top: 14px;
          width: 100%;
          display: inline-flex;
          font-size: 12px;
          border-radius: 0.6rem;

          padding: 0.4rem 1.7rem;
          padding-left: 0.4rem;

          // justify-content: center;
          align-items: center;
          opacity: 0.7;

          svg {
            font-size: 0.9rem;
            display: inline-block;
            margin-right: 0.3rem;
          }

          &.error {
            color: #b82828;
            background: #e29292e3;
          }
        }

        &:focus {
          background: darken($color: rgb(238, 240, 248), $amount: 4);
        }

        &:not(:first-child) {
          margin-top: 0.6rem;
        }
      }

      button {
        margin-top: 0.6rem;
      }
    }

    .disclaimer {
      font-size: 0.65rem;
    }
  }
}

.btn {
  &,
  &:link {
    display: inline-block;
    padding: 0.8rem 2rem;
    text-align: center;
    outline: none;
    border: none;
    font-family: inherit;
    border-radius: 0.4rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }

  &,
  &:link {
    display: inline-block;
    padding: 0.8rem 2rem;
    text-align: center;
    outline: none;
    border: none;
    font-family: inherit;
    border-radius: 0.4rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }

  &-block {
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-primary {
    background: $primary-color;
    color: $text-color-light;

    &:hover {
      background: darken($color: $primary-color, $amount: 8);
    }
  }
}
