.customTooltip {
  background-color: $primary-color !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: bold !important;
  padding: 10 !important;
  border-radius: 10 !important;
  z-index: 1000000000 !important;

  &.place-top:after {
    border-top-color: $primary-color !important;
  }

  &.place-right:after {
    border-right-color: $primary-color !important;
  }
}
