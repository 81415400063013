.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  font-family: 'Barlow Condensed', sans-serif;
  margin-right: 10px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid #35aaec;
  transition: background-color 0.1s ease;
  width: 60px;
  height: 30px;
  z-index: 2;
  .selectedValue {
    color: white;
    font-weight: 600;
  }
  span {
    font-size: 16px;
  }
}
.toggle-switch .switch::before {
  content: '';
  left: 2px;
  top: 2px;
  width: 18px;
  height: 18px;
  background-color: #dff0fb;
  border-radius: 50%;
  transition: transform 0.1s ease;
  border: 1px solid #35aaec;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(27px);
  background-color: #35aaec;
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: transparent;
}
@media (max-width: 520px) {
  .toggle-switch .switch {
    right: -100px;
  }
}
