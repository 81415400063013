.main-wrapper {
  display: flex;

  .filters,
  .search-results {
    padding-top: 5rem;
  }

  .filters {
    background: #F2F2F2;
    flex-basis: 30%;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .filter-section {
    &:not(:first-child) {
      margin-top: 2rem;
    }

    border-top: 1px solid $primary-color;
    padding-top: 0.3rem;

    &-header {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 1.2rem;
        color: $primary-color;
        font-weight: bold;
      }

      .sub-title {
        color: rgb(144, 144, 144);
      }

      .icon {
        color: $primary-color;
      }
    }

    &-body {
      margin-top: 0.7rem;

      .search-bar {
        display: flex;
        border: 1px solid $primary-color;
        border-radius: 1rem;
        background: transparent;
        position: relative;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $primary-color;
          font-size: 1.2rem;
          padding: 0.4rem;
          cursor: pointer;

          &-right {
            padding-right: 0.7rem;
          }

          &-left {
            padding-left: 0.7rem;
          }
        }

        input {
          flex-grow: 1;
          background: transparent;
          outline: none;
          border: none;
          padding: 0.4rem;
        }
      }
    }
  }

  .search-results {
    flex-basis: 70%;
    padding-left: 5rem;
    padding-right: 5rem;

    &-item {
      &:not(:first-child) {
        margin-top: 4rem;
      }

      &-title {
        color: $primary-color;
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }

      .summary {
        display: flex;
        font-size: 1.3rem;

        &-intro {
          flex-basis: 30%;
          padding-right: 2rem;
          border-right: 0.2rem solid $primary-color;
        }

        &-details {
          padding-left: 2rem;
          flex-basis: 70%;

          .overview {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            h6 {
              font-size: 1.2rem;
              text-transform: uppercase;
              color: #333;
            }

            span {
              color: rgb(95, 116, 161);
              font-weight: bold;
              font-size: 1rem;
            }
          }

          .doc-count {
            margin-bottom: 1rem;
            color: #919CB8;
          }

          .action {
            display: flex;
            justify-content: space-between;

            button {
              border: none;
              outline: none;
              border: 0.1rem solid $primary-color;
              background: transparent;
              border-radius: 10rem;
              padding: 0.5rem 1rem;
              text-transform: uppercase;
              font-size: 1.1rem;
              color: $primary-color;
              transition: background-color 0.3s ease;
              cursor: pointer;

              &.icon {
                position: relative;
                overflow: hidden;

                .icon {
                  position: absolute;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  color: $primary-color;
                  height: 100%;
                  top: 0;
                  padding: 0.7rem;
                }

                &-right {
                  padding-right: 2.9rem;

                  .icon {
                    border-left: 1px solid $primary-color;
                    right: 0;
                  }
                }

                &-left {
                  padding-left: 2.9rem;

                  .icon {
                    border-right: 1px solid $primary-color;
                    left: 0;
                  }
                }
              }

              &:hover {
                color: $text-color-light;
                background-color: $primary-color;
              }

              &:hover .icon {
                color: $text-color-light
              }
            }
          }

        }
      }
    }
  }
}

.radio-btn {
  &:not(:first-child) {
    margin-top: 1rem;
  }

  input {
    display: none;

    &:checked~label {

      span:after {
        opacity: 1;
      }

    }
  }

  label {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    span {
      display: inline-block;
      position: relative;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 2px solid $primary-color;
      background: #fff;
      margin-right: 1rem;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: $primary-color;
        opacity: 0;
        top: 50%;
        left: 50%;
        transform: translate(-53%, -46%);
      }
    }
  }
}