.profilepopup-wrapper {
    .image-container {
        position: relative;

        .image {
            display: block;
            width: 100%;
            height: auto;
        }

        .edit-button {
            position: absolute;
            right: 0.625rem;
            bottom: 0.625rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0.3rem;
            border-radius: 50%;
            background: #C4C4C4;
            cursor: pointer;

            &:hover {
                background: darken($color: #C4C4C4, $amount: 4);
            }

            svg {
                font-size: 1rem;
            }
        }
    }

    .user-info {
        padding: 0.7rem;
        text-align: center;

        span {
            display: inline-block;
            margin-top: 0.1rem;
            color: #a7a9ac;
        }
    }

    .action-list {
        &-item {
            cursor: pointer;
            min-width: 225px;
            text-align: center;

            a {
                text-align: center;
                text-decoration: none;
                color: inherit;
                padding: 0.625rem;
                display: inline-block;
                width: 100%;
            }

            &:hover {
                background-color: $secondary-color;

                // color: $secondary-color;
           
            }

            &--dark {
                background-color: $primary-color;

                &:hover {
                    background-color: darken($color: $primary-color, $amount: 10);

                    // color: $secondary-color;
               
                }
            }

            &--bold {
                font-weight: bold;
                color: $secondary-color;
            }
        }
    }
}
