.header {
  padding: 2rem 5rem 2rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-title {
    font-size: 3rem;
    color: $primary-color;
    position: relative;

    >span {
      position: absolute;
      font-size: 1.5rem;
    }
  }
}